<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-card>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <!-- <v-col cols="12" sm="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="name"
                      rules="required|min:3|max:50"
                    >
                      <v-text-field
                        outlined
                        v-model="name"
                        :error-messages="errors"
                        counter
                        maxlength="50"
                        hint="The name of the language"
                        label="Language"
                        required
                        aria-required="true"
                      ></v-text-field>
                    </validation-provider>
                  </v-col> -->
                  <v-col cols="12" sm="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="isoCode"
                      rules="required"
                    >
                      <v-autocomplete
                        outlined
                        v-model="iso_code"
                        :error-messages="errors"
                        hint="The name of the language"
                        label="Language"
                        required
                        aria-required="true"
                        item-value="iso_code"
                        item-text="name"
                        :value="iso_code"
                        :items="isoCodeList"
                        return-object
                        @change="updateSelectedLanguage"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      rules="required|min:6|max:255"
                    >
                      <v-text-field
                        outlined
                        v-model="description"
                        :error-messages="errors"
                        counter
                        maxlength="255"
                        hint="The display name of the langauge"
                        label="Display Name"
                        aria-required="true"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <!--
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="isoCode"
                      rules="required|min:2|max:5|isISOCode"
                    >
                      <v-autocomplete
                        outlined
                        v-model="iso_code"
                        :error-messages="errors"
                        hint="The iso code of the language"
                        label="Language ISO Code"
                        required
                        aria-required="true"
                        item-value="iso_code"
                        item-text="name"
                        :items="isoCodeList"
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                -->
                <v-row>
                  <v-col cols="12" sm="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="is_active"
                      rules="required"
                    >
                      <v-radio-group
                        v-model="is_active"
                        row
                        aria-required="true"
                        :error-messages="errors"
                      >
                        <template v-slot:label>
                          <div>Status</div>
                        </template>
                        <fieldset>
                          <legend class="d-none" aria-hidden="true">
                            Status
                          </legend>
                          <v-radio :label="`Active`" :value="true"></v-radio>
                          <v-radio :label="`Inactive`" :value="false"></v-radio>
                        </fieldset>
                      </v-radio-group>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { required, min, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("passive");

extend("required", {
  ...required,
  message: "This field is required.",
});

extend("min", {
  ...min,
  message: "Minimum {length} characters.",
});

extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});

extend("isISOCode", {
  validate(value) {
    if (value) {
      const isoRegex1 = /^[a-z]{2}_[a-z]{2}$/;
      const isoRegex2 = /^[a-z]{2}$/;
      return isoRegex1.test(value) || isoRegex2.test(value);
    }
    return false;
  },
  message: "This value must be a valid language iso code, ex: en_us or en",
});

export default {
  name: "LanguagesForm",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: { ValidationObserver, ValidationProvider },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      snackbar: false,
    };
  },
  computed: {
    ...mapFields("systemLanguages", {
      name: "language.name",
      description: "language.description",
      iso_code: "language.iso_code",
      is_active: "language.is_active",
    }),
    ...mapGetters("systemLanguages", ["isoCodeList"]),
    ...mapState("systemLanguages", ["error"]),
    isNew() {
      return this.id === "";
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("systemLanguages", [
      "addLanguage",
      "clearLanguage",
      "clearLanguages",
      "editLanguage",
      "getLanguage",
    ]),
    updateSelectedLanguage(e) {
      this.name = e.name;
      this.iso_code = e.iso_code;
    },
    async submit() {
      const formValid = await this.$refs.observer.validate();

      if (this.isSubmitting || !formValid) return;
      let success = false;
      this.isSubmitting = true;
      if (this.isNew) {
        success = await this.addLanguage();
      } else {
        success = await this.editLanguage(this.id);
      }
      if (success !== false) {
        await this.$router.push({
          name: "LanguagesIndex",
        });
      } else {
        this.snackbar = !!this.error;
      }
      this.isSubmitting = false;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    let actionButtons = [];
    if (this.isNew) {
      await this.clearLanguage();
      this.setTitleObject("Language");
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/languages",
        },
        index: 0,
      });
    } else {
      await this.getLanguage(this.id).catch(() => {
        this.$router.push({ name: "NotFound" });
      });
      this.originalIdentification = this.identification;
      this.setTitleObject(this.name);
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/languages",
        },
        index: 0,
      });
    }
    this.isLoading = false;
    this.setBreadcrumbs();
    actionButtons.push({
      component: "SaveButton",
      attributes: {
        onSubmit: () => this.submit(),
      },
      index: 1,
    });
    this.setActionButtons(actionButtons);
  },
  destroyed() {
    this.clearLanguage();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped>
fieldset {
  border: 0;
  display: inline-flex;
}
</style>
